import { DatePicker, Form, Input, InputNumber, Select } from "antd";
import { DefaultOptionType } from "antd/es/select";
import axiosClient from "api/axiosClient";
import { ButtonOutline, ButtonSolid } from "component/button";
import useService from "hook/useService";
import { toast } from "react-toastify";
import Styles from "./styled";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import MESSAGES from "constant/messages";
import { ITabData } from "..";
import React, { useState } from "react";
import { DATE_FORMAT, JP_DATEPICKER_FORMAT } from "constant/date";
import NumericInput from "component/NumberInput";
import { PercentageOutlined } from "@ant-design/icons";
import REGEX from "constant/regex";
import { NumericFormat } from "react-number-format";
interface IProps {
  onChangeTab?: (tabData: ITabData) => void;
  isActive?: boolean;
  copiedId?: number;
}
export default function CreateExpense(props: IProps) {
  const [form] = Form.useForm();
  const service = useService();
  const { id } = useParams();
  const [disable, setDisable] = useState(true);
  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = async (formData: any) => {
    setSubmitting(true)
    try {
      const res = await axiosClient({
        method: "post",
        url: service.PUBLIC_EXPENSE,
        data: {
          ...formData,
          patient_id: id,
          start_date: formData.date[0].format(DATE_FORMAT),
          end_date: formData.date[1].format(DATE_FORMAT),
        },
      });
      toast.success(res.data.success);
      props.onChangeTab?.({ activeKey: "list" });
    } catch (error) {
      toast.error((error as string).toString());
    } finally {
      setSubmitting(false)
    }
  };
  const fetchInitialData = async () => {
    try {
      const { data } = await axiosClient.get(
        service.PUBLIC_EXPENSE + "/" + props.copiedId
      );
      form.setFieldsValue({
        ...data,
        birth_date: data.birth_date && dayjs(data.birth_date),
        period_contract: data.period_contract && dayjs(data.period_contract),
        date: [
          data.start_date && dayjs(data.start_date),
          data.end_date && dayjs(data.end_date),
        ],
      });
    } catch (error) {}
  };

  React.useEffect(() => {
    form.resetFields();
    if (props.copiedId && props.isActive) {
      fetchInitialData();
    }
  }, [props]);

  return (
    <Form form={form} onFinish={handleSubmit} initialValues={{}}>
      <Styles>
        {/* <div className="text-right mb-3">
          <ButtonSolid onClick={() => setDisable(false)} width={137} fontSize={14}>
            編集
          </ButtonSolid>
        </div> */}
        <div className="max-w-[700px]">
          <Form.Item
            label="公費適用期間"
            name="date"
            rules={[{ required: true, message: MESSAGES.requireField }]}
          >
            <DatePicker.RangePicker
              className="w-full"
              format={JP_DATEPICKER_FORMAT}
            />
          </Form.Item>
          <Form.Item
            label="制度名"
            name="system_name"
            rules={[
              { required: true, message: MESSAGES.requireField },
              {
                pattern: REGEX.atLeastOneNonSpaceCharacter,
                message: MESSAGES.requireField,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="公費負担者番号"
            name="cost_number"
            rules={[{ required: true, message: MESSAGES.requireField }]}
          >
            <NumericInput />
          </Form.Item>
          <Form.Item
            label="公費受給者番号"
            name="beneficiary_number"
            rules={[{ required: true, message: MESSAGES.requireField }]}
          >
            <NumericInput />
          </Form.Item>
          <Form.Item
            label="給付率"
            name="benefit_rate"
            rules={[{ required: true, message: MESSAGES.requireField }]}
          >
            <NumericFormat
              isAllowed={({ floatValue, value }) =>
                Boolean(
                  (floatValue !== undefined &&
                    floatValue <= 100 &&
                    floatValue >= 0) ||
                    value === ""
                )
              }
              customInput={Input}
              allowNegative={false}
              className="w-[100px]"
              addonAfter={<PercentageOutlined />}
            />
          </Form.Item>
          <Form.Item label="本人負担額" name="payment_amount">
            <NumericFormat
              customInput={Input}
              allowNegative={false}
              addonAfter={<span style={{ fontSize: "16px" }}>円</span>}
            />
          </Form.Item>
        </div>
        <div className="text-right">
          <ButtonOutline
            className="mr-[8px]"
            width={137}
            onClick={() => props.onChangeTab?.({ activeKey: "list" })}
          >
            キャンセル
          </ButtonOutline>
          <ButtonSolid width={137} onClick={form.submit} isLoading={submitting}>
            保存
          </ButtonSolid>
        </div>
      </Styles>
    </Form>
  );
}
